<template>
  <div class="NewsDetail">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="head">Introduction to Chinese Government Scholarships</div>
        <el-divider></el-divider>
        <div class="__cont__">
          <p class="jieshao">In order to promote the mutual understanding, cooperation and exchanges in various fields between China and other countries, the Chinese government has set up a series of scholarship programs to sponsor international students, teachers and scholars to study and conduct research in Chinese universities.</p>
          <p class="jieshao">China Scholarship Council (hereinafter referred to as CSC), entrusted by the Ministry of Education of the People’s Republic of China (hereinafter referred to as MOE), is responsible for the enrollment and the administration of Chinese Government Scholarship programs.</p>
          <p class="jieshao">Now, 289 designated Chinese universities offer a wide variety of academic programs in science, engineering, agriculture, medicine, economics, legal studies, management, education, history, literature, philosophy, and fine arts for scholarship recipients at all levels.</p>
          <div class="tit">I CHINESE GOVERNMENT SCHOLARSHIP PROGRAMS</div>
          <p class="_b"> 1. Bilateral Program</p>
          <p class="txt">This includes full or partial scholarships in accordance with the educational exchange agreements or consensus between the Chinese government and governments of other countries, institutions, universities or international organizations. It supports undergraduate students, graduate students, general scholars and senior scholars.</p>
          <p class="txt">Applicants shall apply to the dispatching authorities for overseas study of their home countries.</p>
          <p class="_b"> 2. Chinese University Program</p>
          <p class="txt">This is a full scholarship for designated Chinese universities and certain provincial education offices in specific provinces or autonomous regions to recruit outstanding international students for graduate studies in China. It only supports undergraduates, postgraduate students and doctoral students.</p>
          <p class="txt">Applicants shall apply to the designated Chinese universities undertaking this program.</p>
          <p class="_b">3. Great Wall Program</p>
          <p class="_b"></p>
          <p class="txt">This is a full scholarship for the United Nations Educational, Scientific and Cultural Organization (UNESCO) to sponsor students and scholars in developing countries to study and research in China. It only supports general scholars and senior scholars.</p>
          <p class="txt">Applicants shall apply to the National Commissions for UNESCO in their home countries.</p>
          <p class="_b">4. EU Program</p>
          <p class="txt">This is a full scholarship to encourage students from EU member countries to study and research in China in order to promote mutual understanding between China and EU members. It supports undergraduate students, graduate students, general scholars and senior scholars.</p>
          <p class="txt">Applicants shall apply to the Office for Education and Culture, Mission of the P. R. China to the European Union.</p>
          <p class="_b">5. AUN Program</p>
          <p class="txt">This is a full scholarship for the ASEAN University Network (AUN) to sponsor students, teachers and scholars from ASEAN member nations to study in China and to enhance the academic exchange and mutual understanding between China and ASEAN members. It only supports graduates.</p>
          <p class="txt">Applicants shall apply to AUN Secretariat.</p>
          <p class="_b">6. PIF Program</p>
          <p class="txt">This is a full scholarship to sponsor students from Pacific island countries to study in China. It supports undergraduates, graduates, general scholars and senior scholars.</p>
          <p class="txt">Applicants shall apply to Pacific Islands Forum (PIF) Secretariat.</p>
          <p class="_b">7. WMO Program</p>
          <p class="txt">This is scholarship for the World Meteorological Organization (WMO) to sponsor international students to study and conduct research in meteorology, hydrology, and water resources supervision and management in China. It only supports undergraduates and graduates.</p>
          <p class="txt">Applicants shall apply to WMO.</p>
          <div class="tit">II SUPPORTING CATEGORIES, DURATION, INSTRUCTION LANGUAGE, INSTITUTIONS ANDMAJORS</div>
          <p class="_b">Supporting Categories</p>
          <p class="txt">Chinese Government Scholarship programs sponsor international students, teachers and scholars to undertake degree studies (bachelor, master and PhD) and Chinese language study or academic research in institutions of higher education in China.</p>
          <p class="_b">Duration</p>
          <p class="txt">Chinese Government Scholarship covers both major study and Chinese language (preparatory) study. The table below illustrates the duration of each program.</p>
          <div class="_table">
            <div class="_row _head">
              <div class="_col w25">Supporting Categories</div>
              <div class="_col w25">Major Study(year)</div>
              <div class="_col w25">Chinese Language(Preparatory Study)(year)</div>
              <div class="_col w25">Duration of Scholarship(year)</div>
            </div>
            <div class="_row">
              <div class="_col w25">Undergraduate students</div>
              <div class="_col w25">4-5</div>
              <div class="_col w25">1-2</div>
              <div class="_col w25">4-7</div>
            </div>
            <div class="_row">
              <div class="_col w25">Master’s students</div>
              <div class="_col w25">2-3</div>
              <div class="_col w25">1-2</div>
              <div class="_col w25">2-5</div>
            </div>
            <div class="_row">
              <div class="_col w25">Doctoral students</div>
              <div class="_col w25">3-4</div>
              <div class="_col w25">1-2</div>
              <div class="_col w25">3-6</div>
            </div>
            <div class="_row">
              <div class="_col w25">General scholars</div>
              <div class="_col w25">up to 1</div>
              <div class="_col w25">up to 1</div>
              <div class="_col w25">up to 2</div>
            </div>
            <div class="_row">
              <div class="_col w25">Senior scholars</div>
              <div class="_col w25">up to 1</div>
              <div class="_col w25">up to 1</div>
              <div class="_col w25">up to 2</div>
            </div>
          </div>
          <p class="_b">Instruction Language</p>
          <p class="txt">1. Undergraduate scholarship recipients must register for Chinese-taught credit courses. They are required to take one-year preparatory courses in one of the 10 universities listed below and to pass the required test before moving on to their major studies.</p>
          <p class="txt">Undergraduate scholarship recipients can apply for preparatory course exemption if they completed their secondary education in Chinese or have a valid HSK certificate that meets the requirements of the host university. Official documents from secondary schools or a photocopy of a valid HSK certificate must be submitted for a preparatory course exemption application. Please NOTE that HSK results are valid for only 2 years.</p>
          <p class="txt">Entrusted by MOE, the following 10 universities offer preparatory courses to undergraduate scholarship recipients. They are Tianjin University, Nanjing Normal University, Shandong University, Central China Normal University, Tongji University, Beijing Language and Culture University, Northeast Normal University, Beijing International Studies University, Capital Normal University, and the University of International Business and Economics.</p>
          <p class="txt">2. Graduate and non-degree scholarship students can register for either the Chinese-taught program or the English-taught program if applicable. Program Search (By visiting http://www.csc.edu.cn/studyinchina or http://www.campuschina.org)can help you find the program and university you're interested in.</p>
          <p class="txt">All applicants are requested to provide their language proficiency certificates for the scholarship applications. Applicants for Chinese-taught programs must provide a valid Chinese Proficiency Test (HSK) certificate. Undergraduate or non-degree (general scholar & senior scholar) candidates should have achieved HSK Level III or above; Postgraduate candidates should have achieved HSK Level IV or above. Applicants for English-taught programs should provide valid English Proficiency Test (IELTS or TOFEL) certificates.</p>
          <p class="txt">Scholarship recipients of Chinese-taught programs without adequate Chinese proficiency must take Chinese language courses for one to two academic years to reach the language requirements of their host universities before moving on to their major studies. Failure to reach the required language proficiency will lead to the automatic termination of scholarship. Chinese language courses will be one year for majors in Science, Engineering, Agriculture, West Medicine, Economics, Management, Legal Studies and Fine Arts, and be no more than two years for majors in Literature, History, Philosophy and Chinese Medicine.</p>
          <p class="txt">Scholarship recipients of the English-taught programs or those with adequate Chinese language proficiency do not need to take Chinese language courses.</p>
          <p class="_b">Institutions and Majors:</p>
          <p class="txt">Now 289 Chinese universities have been designated to enroll international students with Chinese Government Scholarship, and they offer a wide variety of programs. Introduction to the universities and the programs they offer can be found in the following links.</p>
          <p class="txt"><a href="http://www.csc.edu.cn/studyinchina">http://www.csc.edu.cn/studyinchina</a></p>
          <p class="txt"><a href="http://www.campuschina.org">http://www.campuschina.org</a></p>
          <div class="tit">III COVERAGE and STANDARD</div>
          <div class="_table">
            <div class="_row _head">
              <div class="_col w14">Supporting Categories</div>
              <div class="_col w14">Field of Study</div>
              <div class="_col w14">Tuition(CNY per year)</div>
              <div class="_col w14">Accommodation(CNY per year)</div>
              <div class="_col w14">Stipend(CNY per year)</div>
              <div class="_col w14">Medical Insurance (CNY per year)</div>
              <div class="_col w14">Total (CNY per year)</div>
            </div>
            <div class="_row">
              <div class="_col w14">Undergraduate students</div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">Ⅰ</div>
                  <div class="_colm">Ⅱ</div>
                  <div class="_colm">Ⅲ</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">20000</div>
                  <div class="_colm">23000</div>
                  <div class="_colm">27000</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">8400</div>
                  <div class="_colm">8400</div>
                  <div class="_colm">8400</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">30000</div>
                  <div class="_colm">30000</div>
                  <div class="_colm">30000</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">800</div>
                  <div class="_colm">800</div>
                  <div class="_colm">800</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">59200</div>
                  <div class="_colm">62200</div>
                  <div class="_colm">66200</div>
                </div>
              </div>
            </div>
            <div class="_row">
              <div class="_col w14">Undergraduate students</div>
              <div class="_col w14">
                <div class=" nei">
                  <div class="_colm">Ⅰ</div>
                  <div class="_colm">Ⅱ</div>
                  <div class="_colm">Ⅲ</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">25000</div>
                  <div class="_colm">29000</div>
                  <div class="_colm">34000</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">8400</div>
                  <div class="_colm">8400</div>
                  <div class="_colm">8400</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">36000</div>
                  <div class="_colm">36000</div>
                  <div class="_colm">36000</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">800</div>
                  <div class="_colm">800</div>
                  <div class="_colm">800</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">70200</div>
                  <div class="_colm">74200</div>
                  <div class="_colm">79200</div>
                </div>
              </div>
            </div>
            <div class="_row">
              <div class="_col w14">Undergraduate students</div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">Ⅰ</div>
                  <div class="_colm">Ⅱ</div>
                  <div class="_colm">Ⅲ</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">33000</div>
                  <div class="_colm">38000</div>
                  <div class="_colm">45000</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">12000</div>
                  <div class="_colm">12000</div>
                  <div class="_colm">12000</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">42000</div>
                  <div class="_colm">42000</div>
                  <div class="_colm">42000</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">800</div>
                  <div class="_colm">800</div>
                  <div class="_colm">800</div>
                </div>
              </div>
              <div class="_col w14">
                <div class="nei">
                  <div class="_colm">87800</div>
                  <div class="_colm">92800</div>
                  <div class="_colm">99800</div>
                </div>
              </div>
            </div>
          </div>
          <p class="txt"><b>NOTE:</b> Field of Study I includes Philosophy, Economics, Legal Studies, Education, Literature (Fine Arts excluded), History, and Management; Field of Study II includes Science, Engineering, and Agriculture; Field of Study III includes Fine Arts and Medicine.</p>
          <p class="_b">1. Full Scholarship covers</p>
          <p class="txt">Tuition waiver. Tuition funds will be comprehensively used by the host university. It may cover scholarship students’ education, administration costs and expenditures to support student activities.</p>
          <p class="txt">Accommodation: free university dormitory or accommodation subsidy.</p>
          <p class="txt">If the host university requires students to live on campus, the university will accommodate the scholarship students in a university dormitory (usually a twin room); if the host university permits students to live off campus, the university will provide monthly/quarterly accommodation subsidy:</p>
          <p class="txt">undergraduate students (preppies), master’s students(general scholars): CNY 700 per month;</p>
          <p class="txt">doctoral students (senior scholars): CNY 1000 per month.</p>
          <p class="txt pian">Stipend:</p>
          <p class="txt">Undergraduate students: CNY 2,500 per month;</p>
          <p class="txt">Master’s students/general scholars: CNY 3,000 per month;</p>
          <p class="txt">Doctoral students/senior scholars: CNY 3,500 per month.</p>
          <p class="txt">Within the scholarship duration, registered scholarship students will receive a stipend from their host university each month. Students registering on or before the 15thof the month will receive a full stipend of that month. Those who register after the 15thof the month will receive a half stipend of that month. Graduating students will receive stipend until half month after the graduation date. If registered student stays out of China for more than 15 days due to a personal reason (school holidays excluded), his stipend will be stopped during his leaving.</p>
          <p class="txt">Comprehensive medical insurance. (Please visit http://www.csc.edu.cn/studyinchina or http://www.campuschina.org and refer to Comprehensive Insurance & Protection Scheme for Foreigners Staying in China for insurance policy.)</p>
          <p class="_b">2. Partial scholarship covers</p>
          <p class="txt">One or some items of the full scholarship.</p>
        </div>
        <el-divider>end</el-divider>
        <div class="_navss">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
export default {
  data () {
    return {
      newsDetail: {
        newsDetail: '',
        content: '',
        updated_at: '',
      }
    }
  },
  beforeMount () {
    console.log()
    axiosApi(
      'news/' + this.$route.params.id, {}, 'get'
    ).then((res) => {
      this.newsDetail = res.data
    })
  },
  methods () {

  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.cont {
  .head {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  .date {
    font-size: 14px;
    color: #545454;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 20px;
  }
}

._table {
  margin: 0 auto;
  margin-top: 20px;
  ._row {
    border: 1px solid #dedede;
    margin-bottom: -1px;
    overflow: hidden;
    display: flex;
    font-size: 14px;
    &._head {
      background-color: #f1f1f1;
    }
    ._col {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 40px;
      border-right: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
      text-align: center;
      margin-bottom: -1px;
      &:last-child {
        border-right: none;
      }
      &.w100 {
        width: 100%;
      }
      &.w80 {
        width: 80%;
      }

      &.w75 {
        width: 75%;
      }
      &.w50 {
        width: 50%;
      }
      &.w25 {
        width: 25%;
      }
      &.w40 {
        width: 40%;
      }
      &.w20 {
        width: 20%;
      }
      &.w16 {
        width: 16.6666666%;
      }
      &.w14 {
        width: 14.2857%;
      }
      ._left {
        padding: 10px;
        width: 100%;
        height: 100%;
        text-align: left;
        &.stips {
          p::before {
            content: "·";
            font-weight: bold;
            margin-right: 5px;
          }
        }
        p {
          line-height: 1.7;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &._h-z3 {
      ._col {
        width: 33.333%;
      }
    }
  }
  .nei {
    width: 100%;
    ._colm {
      width: 100%;
      border-bottom: 1px solid #dedede;
      line-height: 40px;
      text-align: center;
      margin-bottom: -1px;
    }
  }
}
.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
p {
  color: #545454;
  line-height: 1.7;
  margin-bottom: 10px;
  margin-top: 10px;
  &.txt {
    font-size: 13px;
  }
  &._b {
    margin-top: 15px;
    font-weight: bold;
  }
  &.pian {
    margin-top: 30px;
  }
}
</style>

<style lang="scss">
.__cont__ {
  margin-top: 30px;
  img {
    max-width: 100%;
  }
}
</style>